$primary-color: #F9F7F7;
$secondary-color: #112D4E;
$font-color: #252525;
$nav-title-color: #F9F7F7;
$nav-item-highlight-color: #3F72AF;
$tab-bottom-bar-color:#3F72AF;
$section-color:#F9F7F7;
$section-font-size: 48px;
$info-font-size: 20px;
$website-name-font-size: 24px;
$tab-font-size: 16px;
$tab-section-font-size: 20px;
$tab-section-info-font-size: 14px;
$grid-font-size: 20px;
$grid-description-font-size: 18px;
$grid-mobile-font-size: 28px;
$grid-mobile-description-font-size: 20px;
$feature-point-title-font-size: 32px;

:root {
  --primary-color: #{$primary-color};
  --secondary-color: #{$secondary-color};
  --font-color: #{$font-color};
  --tab-bottom-bar-color: #{$tab-bottom-bar-color};
  --section-color: #{$section-color};
  --nav-title-color: #{nav-title-color};
  --nav-item-highlight-color: #{nav-item-highlight};
}
.breaklineContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 height: 100%;
 padding-left: 200px;
 padding-right: 200px;background-color: #ffffff;
}



.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom:24px;
  width: 100%;
  margin-top: 24px;
}
.pageContainer {
  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  padding-left: 200px;
  padding-right: 200px;
}
.pageContainerNoPadding {
  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  padding-bottom: 20px;
}
.fullPage {
  display: flex;
  max-width: 100vw;
  flex-direction: column;
}



.column{
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1050px) {
  
  .pageContainer {
    padding-left: 24px;
    padding-right: 24px;
  }
  .breaklineContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   height: 100%;
   padding-left: 24px;
   padding-right:24px;background-color: #ffffff;
  }
  
}
