

@import '../../theme/styles/commonStyle.module.scss';
.imgHorizontal{
    max-width: 50%;
    flex:1;
    display: flex;
    border-radius: 16px;
    object-fit: cover;
    margin-right: 24px;

}
.imgVertical{
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    flex:1;
    display: flex;
    border-radius: 16px;
}
.column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.row{
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    margin-bottom: 12px;
}

.description{
    font-size: $info-font-size;
    color:$font-color;
    padding-top: 48px;text-align: justify;
    display: flex;
    flex-direction: column;
}
.descriptions{
    font-size: $info-font-size;
    color:$font-color;
    margin-bottom: 6px;text-align: justify;
    font-weight: bold;
}
@media screen and (max-width: 1050px) {
 
}

@media screen and (max-width: 870px) {
    .imgHorizontal{
        width: 75%;
    max-height: 500px;
    object-fit: cover;
    flex:1;
    display: flex;
    border-radius: 16px;
    margin-right: 0px;
    
    }
    .row{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}