

@import '../../theme/styles/commonStyle.module.scss';

.container{
  display: flex;
  flex-direction: column;
  justify-content: center;align-items: center;
  background-color:#ffffff;
  border-radius: 12px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 12px;width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  }
  .image{
    width: 50px;
    height: 50px;
    margin-bottom: 12px;
  }
  .content{
  display: flex;
  flex-direction: column;
  justify-content: center;align-items: center;
  }
  .itemName{
    font-size:$grid-font-size ;
    color:$font-color;
    text-align: center;
    font-weight: bold;
    margin-bottom: 12px;
    padding-left:24px;
    padding-right:24px;  white-space: pre-line;
  }
  .itemDescriprion{
    font-size: $grid-description-font-size;
    padding-left:24px;
    padding-right:24px;
    text-align: justify;  white-space: pre-line;
  }
  @media screen and (max-width: 1050px) {
  

    .container{
      padding: 12px;width: 100%;
      margin-top: 12px;
      margin-bottom: 12px;
      padding-top: 24px;
      padding-bottom: 24px;
      }

    }
@media screen and (max-width: 430px) {
  

    .container{
      padding: 12px;width: auto;
      margin-top: 12px;
      margin-bottom: 12px;

      }
  .image{
    width: 100px;
    height: 100px;
  }
  .itemName{
    font-size:$grid-mobile-font-size ;
    color:$font-color;   
  }
  .itemDescriprion{
    font-size: $grid-mobile-description-font-size;   
  }
}

@media screen and (max-width: 1050px) {



}
