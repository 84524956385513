@import '../../theme/styles/commonStyle.module.scss';
.introText {
  color: $secondary-color;
  font-size: $section-font-size;
  border-radius: 12px;
  flex:1;
  text-align: center;
  min-width: 300px;text-align: center;
  white-space: pre-line;font-weight: bold;
}
.sectionText {
  color: $font-color;
  font-size: $section-font-size;
  border-radius: 12px;
  flex:1;
  text-align: center;
  min-width: 300px;text-align: center;
  white-space: pre-line;font-weight: bold;
}
.minBox {
  min-height: 10px;
  min-width: 10px;
  background-color: $secondary-color;
}
.line {
  height: 3px;
  background-color: $secondary-color;
  display: flex;
  flex:1;
}
.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom:24px;
  width: 100%;
 
  margin-top: 24px;
}
@media screen and (max-width: 430px) {
  

}

@media screen and (max-width: 1050px) {



}
