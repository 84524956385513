
@import '../../theme/styles/commonStyle.module.scss';
.languageBox{
  display: flex;
  flex-direction: row;
  max-height: 70px;
  min-width: 100px;
  flex:1;
  justify-content:space-between;

}
.root {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background-color: white;
  transition: all 0.3s ease-in-out;

  &:not(.open) {
    pointer-events: none;

    clip-path: circle(0% at 0% 0px);
  }

  &.open {
    clip-path: circle(200% at 0% 0px);
  }
}

.wrapper {
  padding-top: 56px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  * {
    margin-bottom: 40px;
  }
  .link {
    color: #283237;
    text-decoration: none;
  }
  .linkBtn {
    cursor: pointer;
    border: none;
    border-radius: 16px;
    padding: 7px 28px;
    background: #0069ff;
    color: white;
  }
}
