@import '../../theme/styles/commonStyle.module.scss';
.barBlock{
  height: 25px;
  background-color: #ffffff;
}
.bar {
  position: relative;
  background: #ffffff;
  // Top gradient


  // Bottom gradient
  &::after {
    content: '';
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    height: 75px; // Adjust height for the gradient
    background: linear-gradient(to top, rgba(17,45,78, 0) 0%, rgba(17,45,78, 0.5) 100%);
    z-index: 1; // Ensure it is above the background
  }
}

.cover {
  position: relative; // Set the container to relative positioning
  overflow: hidden; // Prevents overflow of the image
  height: 500px; // Set a height for the container
  display: flex;
  justify-content: center;
  align-items: center;
  .box{
    position:absolute;
min-width: 700px;
    background-color: rgba(255,255,255,0.5);
    border-radius:24px;
    padding: 50px;
  }
  .title{
    color:$secondary-color;
    font-size:48px;
    font-weight: bold;
    flex:1;
    text-align: center;
   text-align: center;
    white-space: pre-line;
  }
  .description{
    color: $secondary-color;
    font-size: 24px;
    border-radius: 12px;
    font-weight: bold;
    flex:1;
    text-align: center;
    text-align: center;
    white-space: pre-line;
    margin-top: 12px;
  }
  .coverImage {
    height: 500px;
    z-index: -99;
    position: fixed; // Use absolute positioning to center the image
    left: 50%; // Move the left edge to the center
    transform: translate(-50%, 0%); // Center the image by translating it back by half its width and height
    width: 100%; // Maintain aspect ratio
    object-fit: cover; // Ensure the image covers the entire container
  }
}
@media screen and (max-width: 1050px) {

  .cover {
    position: relative; // Set the container to relative positioning
    height: 400px; // Set a height for the container




    .title{
      color:$secondary-color;
      font-size:36px;
      font-weight: bold;
      flex:1;
      text-align: center;
     text-align: center;
      white-space: pre-line;
    }
    .description{
      color: $secondary-color;
      font-size: 24px;
      border-radius: 12px;
      font-weight: bold;
      flex:1;
      text-align: center;
      text-align: center;
      white-space: pre-line;
    }




    .box{
      position:absolute;
min-width: 75%;
      background-color: rgba(255,255,255,0.5);
      border-radius:24px;
      padding-left: 12px;
      padding-right: 12px;
padding-top: 36px;
padding-bottom: 36px;
    }
 
  }

}

@media screen and (max-width: 500px) {
  .pageContainer {
    padding-left: 24px;
    padding-right: 24px;
  }

  .cover {
    position: relative; // Set the container to relative positioning
    height: 400px; // Set a height for the container




    .title{
      color:$secondary-color;
      font-size:36px;
      font-weight: bold;
      flex:1;
      text-align: center;
     text-align: center;
      white-space: pre-line;
    }
    .description{
      color: $secondary-color;
      font-size: 24px;
      border-radius: 12px;
      font-weight: bold;
      flex:1;
      text-align: center;
      text-align: center;
      white-space: pre-line;
    margin-top: 4px;

    }




    .box{
      position:absolute;
      min-width: 90%;
      background-color: rgba(255,255,255,0.5);
      border-radius:24px;
padding-left: 0;
padding-right: 0px;
padding-top: 12px;
padding-bottom: 12px;
    }
    .coverImage {
    position: relative; // Set the container to relative positioning
    left: 0%; // Move the left edge to the center

      transform: translate(0%, 0%); // Center the image by translating it back by half its width and height
      width: 100%; // Maintain aspect ratio
      height: 100%;
      object-fit: cover; // Ensure the image covers the entire container
    }
  }
}
