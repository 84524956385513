

@import '../../theme/styles/commonStyle.module.scss';

.column{
    display: flex;
    flex-direction: column;
    align-items: center;justify-content: flex-start;
}
.row{
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    margin-bottom: 12px;   
    align-items: flex-start;
}



@media screen and (max-width: 1050px) {
    .column{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .row{
        align-items: center;
        justify-content: center;
        }
}

@media screen and (max-width: 700px) {
    
    .row{
        flex-direction: column;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}