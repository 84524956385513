

@import '../../theme/styles/commonStyle.module.scss';
.block{
  font-size:$website-name-font-size;
  color:$nav-title-color;
  min-width: 100px;
 // background-color:$primary-color;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex:1;
  
  display: inline-block; // Adjust width according to text length

  white-space: nowrap;  // Prevent text wrapping
  overflow: hidden;     // Hide overflow if any
  text-overflow: ellipsis; // Add ellipsis for overflow text (optional)


}
.bar {
  z-index: 100;

  display: flex;

  align-items: flex-start;
  justify-content: flex-start;

  transition: 0.3s;
  &.raised {
    //   background-color: black;
  }
}

.renderBtnsBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
.renderLeftBox {
  display: flex;
  flex-direction: row;
  flex:1;align-items: center;
  justify-content: flex-start;
  padding-left:180px;
}
.renderLeftBar{
  width:5px;
  margin-left: 12px;
  margin-right: 12px;
  height: 100%;
 // background-color: $secondary-color;
}
.renderRightBox {
  display: flex;
  flex-direction: row;
  flex:1;
  justify-content: flex-end;
  padding-right:200px;
}
.languageBox{
  max-width: 150px;
  display: flex;
  flex:1;
  flex-direction: row;
  justify-content:space-between;align-items: center;

}
.topIcon {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #ffffff;
  background-size: 100% 190px;
  background-position: center;
  flex-direction: column;
}

.bottomIcon {
  display: flex;
  justify-content: center;
  height: 40px;
  width:100vw;
  background-color: rgba(0, 0, 0, 0.8); /* 滾動後的背景顏色 */
  position: fixed; /* 使头部固定在页面顶部 */
 //border-bottom: 5px solid $secondary-color;

}
.scrolled {

  color: white; /* 滾動後的文字顏色 */
}
.menusContainer {
  color: white;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  * {
  }
  .menuIcon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
  .forceHeight {
    height: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .link {
    color:$nav-title-color;
    text-decoration: none;
    font-size:$tab-font-size;
    font-weight: 400;
    cursor: pointer;
    &:hover,
    &:focus {
      color: $nav-item-highlight-color;
    }
  }

  &.hideMenu {
    display: none;
  }
}
.smalContainer {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  flex:1;
  margin-left: 8px;
  margin-right: 8px;width: 100%;
  justify-content: center;

}
.smalContainerSelected {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  flex:1;
  min-width: 75px;
justify-content: center;
  border-bottom: 5px solid $secondary-color;
  margin-left: 12px;
  margin-right: 12px;
}
.menuButton {
  display: none;
  cursor: pointer;
  background: none;
  border: none;

  &.collapsed {
    display: block;
  }
}
.hamburger {
  position: relative;
  width:24px;
  height:24px;

  padding: 4.5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div {
    width: 100%;
    height: 4px;
    border-radius: 2;
    background-color: #ffffff;
    opacity: 1;
    transition: 0.3s;
  }
  &.close {
    :nth-child(1) {
      transform: rotate(45deg) translate(7px, 7px) scale(1.5);
    }
    :nth-child(2) {
      opacity: 0;
    }
    :nth-child(3) {
      transform: rotate(-45deg) translate(7px, -7px) scale(1.5);
    }
  }
}
@media screen and (max-width: 1050px) {
  .renderLeftBox {
    padding-left:0px;
  }
  .renderRightBox{
    padding-right: 14px;
  }
  .renderLeftBar{
    display: none;
  }
  .menusContainer {
    display: none;
  }
  .smalContainer {
    margin-left: 5px;
    margin-right: 5px;
  }
  .smalContainerSelected {
    margin-left: 5px;
    margin-right: 5px;
  }
  .menuButton {
    display:block;
    cursor: pointer;
    background: none;
    border: none;
   
  }

  .block{
  padding-left: 12px;
padding-right: 12px;
    
  }
  .bottomIcon {
    display: flex;
    justify-content: center;
   // border-bottom: 5px solid $secondary-color;  padding-left: 0px;
    padding-right: 0px;
  }
}











