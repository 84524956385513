

@import '../../theme/styles/commonStyle.module.scss';
.block{
  margin-bottom: 20px;
}
.carouselImg{
  height: 400px;
  object-fit: contain;
  background-color: $secondary-color;
  margin-bottom: 48px;
}
.sectionText {
  color: $font-color;
  font-size: $section-font-size;
  border-radius: 12px;
  flex:1;
  text-align: center;
  min-width: 300px;text-align: center;
  white-space: pre-line
}
.sectionContainer{


}
.carouselContainer{
display: flex;
justify-content: flex-end;
align-items: flex-end;

}.sectionContainerCenter{

align-items: center;justify-content: center;
}

.infoStringText{
  font-size: $info-font-size;
  color:$font-color;
  margin-top: 12px;text-align: justify;
  padding-left: 72px;
  padding-right: 72px;
}
.infoStringCenterText{
  font-size: $info-font-size;
  color:$font-color;
  margin-top: 12px;text-align: center;
  white-space: pre-line;
  padding-left: 72px;
  padding-right: 72px;
}
@media screen and (max-width: 1256px) {
  .carouselImg{

    background-color:rgba(0,0,0,0);
  }

  .sectionText {
  }


}
@media screen and (max-width: 1050px) {
  .carouselImg{

    background-color:#ffffff;
  }

  .sectionText {
  }


}
@media screen and (max-width: 420px) {



  .infoStringCenterText{
text-align:justify;
  }

}

