@import '../../theme/styles/commonStyle.module.scss';

.footer {
  display: flex;
  flex:1;
  justify-content: flex-end;
  flex-direction: column;
  padding-bottom: 38px;
  padding-top: 38px;
  margin-top: 24px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  border-bottom: 20px solid $secondary-color;  
  background-color: #ffffff;
}
.topLayout {
  display: flex;
  flex-direction: column;
  flex:1;

  padding-left: 100px;
  padding-right: 100px;
}
.label{
  color:$font-color;
  font-weight: bold;
  font-size: 18px;
  width: 150px;
}
.clickMe{
  color:rgb(164, 255, 167);
  font-weight: bold;
  font-size: 18px;
}
.text{
  color:$font-color;
  width:70%;
  font-size: 18px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  cursor: pointer;
}
.textBox{
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  margin-bottom: 6px;
}
.textBoxes{
  display: flex;
  flex-direction: column;
  
}
.bottomText{
  color:#ffffff;
  font-weight: bold;
  font-size: 18px;
  width: 100%;
  background-color: #16321b;
  text-align: center;
}
.section{
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 1050px) {
  .text{
    width:100%;

  }
  .footer {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    height: 100%;
  }

  .topLayout {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;
    padding-right: 30px;
    
  }

  .section{
    
    flex-direction: column;
    align-items: flex-start;
  }



    

  .clickMe{
    margin-top: 48px;
    
  }

  .textBox{
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  
}
